/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../scss/backend/style.scss');
require('../scss/backend/colorscheme.scss');
require('leaflet/dist/leaflet.css');
require('bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css');

require('leaflet');
require('daterangepicker');
require('html5shiv');
require('magnific-popup');
require('echarts');
require('bootstrap');
require('bootstrap-select');
require('bootstrap-tagsinput');
require('bootstrap-datepicker');
require('./backend/forms/bootstrap-tagsinput.js');
require('./backend/forms/bootstrap-select.js');
require('./backend/jquery.multi-select.js');
require('./backend/forms/multi-select.js');
require('./backend/forms/validation.js');
require('./backend/forms/filters.js');
require('./backend/forms/entities.js');
require('./backend/forms/datepicker.js');
require('./backend/forms/list-select.js');
require('./backend/global.js');
require('waves/dist/waves.min.js');
require('sticky-kit/dist/sticky-kit.min.js');
require('./backend/magnific-popup-init.js');
require('sweetalert');
// Cause problems :-(
// require('respond.js/dest/respond.min.js');
const $ = require('jquery');
require('jquery-slimscroll');
// const moment = require('moment/moment.js');
