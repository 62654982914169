/* global swal reportUrl */

function UpdateQueryString(key, value, url) {
    'use strict';

    if (!url) url = window.location.href;
    let re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi');
    let hash;

    if (re.test(url)) {
        if ('undefined' !== typeof value && null !== value) {
            return url.replace(re, '$1' + key + '=' + value + '$2$3');
        }
        hash = url.split('#');
        url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
        if ('undefined' !== typeof hash[1] && null !== hash[1]) {
            url += '#' + hash[1];
        }
        return url;
    }
    if ('undefined' !== typeof value && null !== value) {
        let separator = -1 !== url.indexOf('?') ? '&' : '?';
        hash = url.split('#');
        url = hash[0] + separator + key + '=' + value;

        if ('undefined' !== typeof hash[1] && null !== hash[1]) {
            url += '#' + hash[1];
        }

        return url;
    }

    return url;
}

$('#generate-report').on('click', function (e) {
    'use strict';

    let fields = '';
    let count = 0;
    $('input.report-field-checkbox[type="checkbox"]').each(function () {
        if ($(this).is(':checked')) {
            fields += '&fields[' + count + ']=' + $(this).val();
            count += 1;
        }
    });
    window.location.href = reportUrl + encodeURI(fields);
    setTimeout(function () {
        $('#generateReport').modal('hide');
    }, 1000);
});

$('.select-all-checkbox').on('click', function (e) {
    'use strict';

    e.stopPropagation();

    if ($(this).is(':checked')) {
        $('a.pdf-selected').show();
        $('button.delete-selected').show();
        $('button.mark-as-read-selected').show();
        $('button.assign-selected').show();
        $('div.assign-select').show();
        $('button.status-selected').show();
        $('div.status-select').show();

        let tabSelector = '';
        if ($(this).data('tabbed')) {
            tabSelector = $('.nav-tabs .nav-item .nav-link.active').attr('href');
        }

        let ids = [];
        $(tabSelector + ' input[name="list-select"]').each(function () {
            $(this).prop('checked', true);
            ids.push($(this).val());
        });
    } else {
        $('a.pdf-selected').hide();
        $('button.delete-selected').hide();
        $('button.mark-as-read-selected').hide();
        $('button.assign-selected').hide();
        $('div.assign-select').hide();
        $('button.status-selected').hide();
        $('div.status-select').hide();

        let tabSelector = '';
        if ($(this).data('tabbed')) {
            tabSelector = $('.nav-tabs .nav-item .nav-link.active').attr('href');
        }

        let ids = [];
        $(tabSelector + ' input[name="list-select"]').each(function () {
            $(this).prop('checked', false);
        });
    }
});

$('.nav-tabs .nav-item .nav-link').click(function () {
    'use strict';

    if ($(this).hasClass('active')) {
        return;
    }

    $('a.pdf-selected').hide();
    $('button.delete-selected').hide();
    $('button.mark-as-read-selected').hide();
    $('button.assign-selected').hide();
    $('div.assign-select').hide();
    $('button.status-selected').hide();
    $('div.status-select').hide();

    if ($('.select-all-checkbox').length && $('.select-all-checkbox').is(':checked')) {
        $('.select-all-checkbox').prop('checked', false);
    }

    let ids = [];
    $('input[name="list-select"]').each(function () {
        $(this).prop('checked', false);
    });
});

$('.list-select-checkbox').on('click', function (e) {
    'use strict';

    e.stopPropagation();

    if ($('.select-all-checkbox').length && $('.select-all-checkbox').is(':checked')) {
        $('.select-all-checkbox').prop('checked', false);
    }

    let selected = false;
    $('input[name="list-select"]:checked').each(function () {
        selected = true;
    });
    if (selected) {
        $('a.pdf-selected').show();
        $('button.delete-selected').show();
        $('button.mark-as-read-selected').show();
        $('button.assign-selected').show();
        $('div.assign-select').show();
        $('button.status-selected').show();
        $('div.status-select').show();

        let ids = [];
        $('input[name="list-select"]:checked').each(function () {
            ids.push($(this).val());
        });
        let pdfUrl = $('a.pdf-selected').attr('href');
        pdfUrl = UpdateQueryString('ids', ids.join(','), pdfUrl);
        $('a.pdf-selected').attr('href', pdfUrl);
    } else {
        $('a.pdf-selected').hide();
        $('button.delete-selected').hide();
        $('button.mark-as-read-selected').hide();
        $('button.assign-selected').hide();
        $('div.assign-select').hide();
        $('button.status-selected').hide();
        $('div.status-select').hide();
    }
});

$('button.mark-as-read-selected').on('click', function () {
    'use strict';

    let ids = [];
    let selected = false;
    $('input[name="list-select"]:checked').each(function () {
        selected = true;
        ids.push($(this).val());
    });

    const url = '/admin/mark-as-read-multiple';
    const data = {
        type: $(this).data('type'),
        ids
    };
    const message = $(this).data('confrimmessage');
    const confirmbuttontext = $(this).data('confirmbuttontext');
    const cancelbuttontext = $(this).data('cancelbuttontext');

    swal({
        title: message,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: confirmbuttontext,
        cancelButtonText: cancelbuttontext
    }).then((willMark) => {
        if (willMark) {
            $.post(url, data).done((res) => {
                if (res.not_marked) {
                    location.reload();
                    /*
                    if (0 >= res.not_marked.length) {
                        location.reload();
                    } else {
                        swal({
                            title: res.not_marked_msg,
                            type: 'successs',
                            cancelButtonText: cancelbuttontext
                        }).then((ok) => {
                            location.reload();
                        });
                    }
                    */
                }
            });
        }
    });
});

$('button.delete-selected').on('click', function () {
    'use strict';

    let ids = [];
    let selected = false;
    $('input[name="list-select"]:checked').each(function () {
        selected = true;
        ids.push($(this).val());
    });

    const url = '/admin/delete-multiple';
    const data = {
        type: $(this).data('type'),
        ids
    };
    const message = $(this).data('confrimmessage');
    const confirmbuttontext = $(this).data('confirmbuttontext');
    const cancelbuttontext = $(this).data('cancelbuttontext');

    swal({
        title: message,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: confirmbuttontext,
        cancelButtonText: cancelbuttontext
    }).then((willDelete) => {
        if (willDelete) {
            $.post(url, data).done((res) => {
                if (res.not_deleted) {
                    if (0 >= res.not_deleted.length) {
                        location.reload();
                    } else {
                        swal({
                            title: res.not_deleted_msg,
                            type: 'successs',
                            cancelButtonText: cancelbuttontext
                        }).then((ok) => {
                            location.reload();
                        });
                    }
                }
            });
        }
    });
});

$('button.assign-selected').on('click', function () {
    'use strict';

    let ids = [];
    let selected = false;
    $('input[name="list-select"]:checked').each(function () {
        selected = true;
        ids.push($(this).val());
    });

    const d = $('#form_manager').select2('data');
    let managerId = null;
    if (d && d[0] && d[0].id) {
        managerId = d[0].id;
    } else {
        return;
    }

    const url = '/admin/assign-multiple';
    const data = {
        type: $(this).data('type'),
        ids,
        manager_id: managerId
    };
    const message = $(this).data('confrimmessage');
    const confirmbuttontext = $(this).data('confirmbuttontext');
    const cancelbuttontext = $(this).data('cancelbuttontext');

    swal({
        title: message,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: confirmbuttontext,
        cancelButtonText: cancelbuttontext
    }).then((willAssign) => {
        if (willAssign) {
            $('.preloader').fadeIn();
            $.post(url, data).done((res) => {
                if (res.not_assigned) {
                    if (0 >= res.not_assigned.length) {
                        location.reload();
                    } else {
                        $('.preloader').fadeOut();
                        swal({
                            title: res.not_assigned_msg,
                            type: 'success',
                            cancelButtonText: cancelbuttontext
                        }).then((ok) => {
                            location.reload();
                        });
                    }
                } else {
                    $('.preloader').fadeOut();
                }
            });
        }
    });
});

$('button.status-selected').on('click', function () {
    'use strict';

    let ids = [];
    let selected = false;
    $('input[name="list-select"]:checked').each(function () {
        selected = true;
        ids.push($(this).val());
    });

    const d = $('#form_status').select2('data');
    let statusId = null;
    if (d && d[0] && d[0].id) {
        statusId = d[0].id;
    } else {
        return;
    }

    const url = '/admin/change-status-multiple';
    const data = {
        type: $(this).data('type'),
        ids,
        status_id: statusId
    };
    const message = $(this).data('confrimmessage');
    const confirmbuttontext = $(this).data('confirmbuttontext');
    const cancelbuttontext = $(this).data('cancelbuttontext');

    swal({
        title: message,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        confirmButtonText: confirmbuttontext,
        cancelButtonText: cancelbuttontext
    }).then((willChange) => {
        if (willChange) {
            $('.preloader').fadeIn();
            $.post(url, data).done((res) => {
                if (res.not_changed) {
                    if (0 >= res.not_changed.length) {
                        location.reload();
                    } else {
                        $('.preloader').fadeOut();
                        swal({
                            title: res.not_changed_msg,
                            type: 'success',
                            cancelButtonText: cancelbuttontext
                        }).then((ok) => {
                            location.reload();
                        });
                    }
                } else {
                    $('.preloader').fadeOut();
                }
            });
        }
    });
});
