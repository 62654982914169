window.addEventListener('load', function () {
    'use strict';

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
            $(this).find('textarea.form-control').each(function () {
                const minlength = $(this).attr('minlength');
                const maxlength = $(this).attr('maxlength');

                let hasError = false;

                if (typeof minlength !== typeof undefined) {
                    hasError = !($(this).val().length >= minlength);
                    if (!hasError && (typeof maxlength !== typeof undefined)) {
                        hasError = !($(this).val().length <= maxlength);
                    }
                } else if (typeof maxlength !== typeof undefined) {
                    hasError = !($(this).val().length <= maxlength);
                }

                if (hasError) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(this).addClass('is-invalid');
                } else {
                    $(this).removeClass('is-invalid');
                }
            });

            if (false === form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        }, false);
    });
}, false);
