if ($('select.selectpicker').length) {

    $('select.selectpicker').each(function(){
        $(this).selectpicker();
        const dataClass = $(this).find('option[value="' + $(this).val() + '"]').data('class');
        $(this).next('button.dropdown-toggle').attr('data-class', dataClass);
    });

    $('select.selectpicker').on('change', function(){
            const dataClass = $(this).find('option[value="' + $(this).val() + '"]').data('class');
            $(this).next('button.dropdown-toggle').attr('data-class', dataClass);
    });
}
