if ($('.entity-province').length) {
    $('.entity-province').on('change', function () {
        'use strict';

        if (!$('.entity-zips').val() || $('.entity-zips').hasClass('create-entity')) {
            const code = $(this).find('option[value="' + $(this).val() + '"]').data('code');
            const minZip = String(code) + '000';
            const maxZip = String(code) + '999';
            const zipsRange = minZip + '-' + maxZip;

            $('.entity-zips').tagsinput('removeAll');
            $('.entity-zips').tagsinput('add', zipsRange);
        }
    });
}
