$(document).ready(function () {
    'use strict';

    $('.multi-select').multiSelect();
    
    $('.multi-select').each(function () {
        'use strict';

        // Do extra stuff
    });
});
