/* global L allIssues getUrlParameter replaceUrlParam */
/* Issue location map */

$(document).ready(function () {
    'use strict';

    $('#items-per-page-select').on('change', function () {
        const ipp = $(this).val();
        let currentIpp = getUrlParameter('ipp');
        if (!currentIpp || (currentIpp != ipp)) {
            let newUrl = replaceUrlParam(window.location.href, 'ipp', ipp);
            newUrl = replaceUrlParam(newUrl, 'page', '1');
            window.location.href = newUrl + '#items-per-page-select';
        }
    });

    $('#select-all').on('click', function () {
        const check = $(this).is(':checked');
        $(this).closest('table').find('tbody .checkbox input[type="checkbox"]').each(function () {
            if (check) {
                if (!$(this).is(':checked')) {
                    $(this).click();
                }
            } else if ($(this).is(':checked')) {
                $(this).click();
            }
        });
    });

    $('.clickable-row').on('click', function (e) {
        if ($(e.target).parent().hasClass('checkbox') || $(e.target).parent().is('a')) {
            return;
        }
        const href = $(this).data('href');
        if (href) {
            setTimeout(function () {
                window.location = href;
            }, 300);
        }
    });

    if ($('label.choice_category').length) {
        $('label.choice_category').each(function () {
            let el = $(this).next();
            let selected = true;
            while (el.hasClass('checkbox') && !el.hasClass('choice_category')) {
                if (!el.find('input').is(':checked')) {
                    selected = false;
                }
                el = el.next();
            }

            if (!selected) {
                $(this).removeClass('selected');
            } else {
                $(this).addClass('selected');
            }
        });
    }

    $('label.choice_category').on('click', function () {
        let el = $(this).next();
        let selected = true;
        while (el.hasClass('checkbox') && !el.hasClass('choice_category')) {
            if (!el.find('input').is(':checked')) {
                selected = false;
            }
            el = el.next();
        }

        el = $(this).next();
        if (!selected) {
            $(this).addClass('selected');
            while (el.hasClass('checkbox') && !el.hasClass('choice_category')) {
                if (!el.find('input').is(':checked')) {
                    el.find('input').click();
                }
                el = el.next();
            }
        } else {
            $(this).removeClass('selected');
            while (el.hasClass('checkbox') && !el.hasClass('choice_category')) {
                if (el.find('input').is(':checked')) {
                    el.find('input').click();
                }
                el = el.next();
            }
        }
    });
});

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function replaceUrlParam(url, paramName, paramValue){
    var pattern = new RegExp('(\\?|\\&)('+paramName+'=).*?(&|$)')
    var newUrl=url
    if(url.search(pattern)>=0){
        newUrl = url.replace(pattern,'$1$2' + paramValue + '$3');
    }
    else{
        var h = window.location.hash;
        if (h) {
            newUrlArr = newUrl.split('#');
            newUrl = newUrlArr[0] + (newUrlArr[0].indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
            newUrl += h;
        } else {
            newUrl = newUrl + (newUrl.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue
        }
    }
    return newUrl
}

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}     
