if ($('input[data-role="tagsinput"]').length) {
    $('input[data-role="tagsinput"]').each(function () {
        'use strict';

        if ($(this).data('pattern')) {
            $(this).on('beforeItemAdd', function (e) {
                if (e.item) {
                    const regExp = new RegExp($(this).data('pattern'));
                    if (! regExp.test(e.item)) {
                        e.cancel = true;
                        alert($(this).data('wrongmessage'));
                    }
                }
            });
        }
    });
}
