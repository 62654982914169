/* global moment applyLabel cancelLabel fromLabel toLabel customRangeLabel weekLabel daysOfWeekArray monthNamesArray keyToday keyYesterday keyLast7Days keyLast30Days keyThisMonth keyLastMonth */

const moment = require('moment/moment.js');

$('.filters-wrapper').each(function () {
    'use strict';

    $(this).find('.filter-item').each(function () {
        if ($(this).find('.daterange').length) {
            const parentSelector = '#' + $(this).attr('id') + ' .filter-content';

            $(this).find('.daterange').daterangepicker({
                parentEl: parentSelector,
                locale: {
                    format: 'DD/MM/YYYY',
                    separator: ' - ',
                    applyLabel,
                    cancelLabel,
                    fromLabel,
                    toLabel,
                    customRangeLabel,
                    weekLabel,
                    daysOfWeek: daysOfWeekArray,
                    monthNames: monthNamesArray,
                    firstDay: 1
                },
                ranges: {
                    [keyToday]: [moment(), moment()],
                    [keyYesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    [keyLast7Days]: [moment().subtract(6, 'days'), moment()],
                    [keyLast30Days]: [moment().subtract(29, 'days'), moment()],
                    [keyThisMonth]: [moment().startOf('month'), moment().endOf('month')],
                    [keyLastMonth]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                },
                alwaysShowCalendars: true,
                autoUpdateInput: false
            });
            $(this).find('.daterange').on('apply.daterangepicker', function (ev, picker) {
                $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
            });
            $(this).find('.daterange').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
            });
        }

        $(this).find('.filter-label').on('click', function () {
            $(this).closest('.filter-item').toggleClass('active');
            $(this).next('.filter-content').slideToggle('fast');
        });
    });
});

$(window).on('click', function (e) {
    'use strict';

    const $target = $(e.target);
    if (!$target.closest('.filter-item.active').length) {
        $('.filter-item.active').find('.filter-content').slideUp('fast');
        $('.filter-item.active').removeClass('active');
    } else if ($target.hasClass('cancelBtn') && $target.closest('.daterangepicker').length) {
        $('.filter-item.active').find('.filter-content').slideUp('fast');
        $('.filter-item.active').removeClass('active');
    }
});

$('#copyEmails').click(function () {
    'use strict';

    /* Get the text field */
    let copyText = document.getElementById('userEmails');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    /* Copy the text inside the text field */
    document.execCommand('copy');
    if (navigator) {
        navigator.clipboard.writeText(copyText.value);
    }

    /* Alert the copied text */
    $('.toast-copy').toast('show');
    setTimeout(function () {
        $('.toast-copy').toast('hide');
        // solve some strange behaviour...
        setTimeout(function () {
            $('.toast-copy').removeClass('fade');
            $('.toast-copy').removeClass('hide');
        }, 1500);
    }, 5000);
});
